<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <div>
    <status-bar
      :progress-percent="selectedNode.cpu"
      :status-name="$t('globals.cpu')"
    />
    <status-bar
      :progress-percent="selectedNode.mem.percent"
      :status-name="$t('nodes.information.memoryUsage')"
    />
    <status-bar
      :above-progress="dockerUsedTotalRelation"
      :progress-percent="selectedNode.docker.percent
      || selectedNode.docker.percent === 0 ? 100 - selectedNode.docker.percent : 0"
      :status-name="$t('nodes.information.dockerSpaceUsage')"
    />
    <status-bar
      v-if="listOfHypervisors.length"
      :above-progress="lvmUsedTotalRelation"
      :progress-percent="selectedNode.lvm.percent
      || selectedNode.lvm.percent === 0 ? 100 - selectedNode.lvm.percent : 0"
      :status-name="$t('nodes.information.vmUsedSpace')"
    />
  </div>
</template>

<script>
import StatusBar from '@/components/state-bar/StatusBar.vue';
import { HYPERVISOR } from '@/constants';

export default {
  components: {
    StatusBar,
  },
  data: () => ({
    listOfHypervisors: [],
  }),
  props: {
    selectedNode: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    selectedNode() {
      this.checkForHypervisor();
    },
  },
  created() {
    this.checkForHypervisor();
  },
  computed: {
    dockerUsedTotalRelation() {
      return this.calculateDigitalSpace(this.selectedNode.docker);
    },
    lvmUsedTotalRelation() {
      return this.calculateDigitalSpace(this.selectedNode.lvm);
    },
  },
  methods: {
    calculateDigitalSpace(values) {
      if (!values.total) {
        return '';
      }
      if (this.selectedNode.lvm.ovdm_version === '2.0') {
        return `${values.used}/${values.total} ${values.unit}`;
      }
      return `${this.$options.filters.digitalSpaceFilter(values.total - values.free)}`;
    },
    checkForHypervisor() {
      this.listOfHypervisors = this.selectedNode.capabilities
        .filter((capability) => HYPERVISOR.includes(capability.name));
    },
  },
};
</script>
