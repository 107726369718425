<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

  <template>
    <div
      v-resize="onResize">
      <v-row
      >
        <v-col
          lg="2"
          md="5"
          sm="7"
          xs="9"
          class="pr-16"
          cols="2"
        >
          <v-img
            id="iiotNodeActionsAndInfoHardwareImage"
            :src="`/img/${selectedNode.model}.png`"
            :lazy-src="`/img/${selectedNode.model}.png`"
            aspect-ratio="0.6"
            contain
            elevation="0"
            max-height="150"
            max-width="150"
            min-height="130"
            min-width="130"

          />
        </v-col>
        <v-col
          lg="3"
          md="5"
          cols="4"
          class="mt-6"
          :class="{'pr-3 pl-12': !isMarginVisible, 'pl-16': isMarginVisible}"
        >
          <div
            class="node-information-text-light text--secondary"
          >
            {{ $t('nodes.information.wanAddress') }}
          </div>
          <div
            class="node-information-text"
          >
            {{ selectedNode.wanAddress || 'N/A' }}
          </div>
          <div
            :class="{ 'node-information-text-light text--secondary pt-2':
             !selectedNode.lastSystemStart,
            'node-information-text-light text--secondary pt-4': selectedNode.lastSystemStart }"
          >
            {{ $t('nodes.information.labels') }}
          </div>
          <div
            class="node-information-text" v-if="selectedNode.connectionStatus === 'offline'
            || labels.length === 0"
          >
            {{ $t('nodes.information.noLabels') }}
          </div>
          <div
            v-if="labels.length !== 0 && selectedNode.connectionStatus === 'online'"
            class="div-labels"
          >
            <v-tooltip
              bottom
              max-width="1100"
            >
              <template v-slot:activator="{ on }">
                <div
                  v-on="on"
                  class="div-labels"
                >
                  <v-list
                    class="labels-expansion-panel node-information-text pa-0"
                    v-for="(label, index) in labels"
                    :key="index"
                    flat
                    dense
                  >
                    <v-list-item
                      class="labels-expansion-panel-list">
                      <div
                        class="cut-text"
                        v-if="index < 3"
                      >
                        {{ label.toString() }}
                      </div>
                      <div
                        class="icon-show-labels"
                        v-if="index === 3"
                      >
                        <v-icon
                          id="iiotNodeActionsAndInfoShowMoreLabels"
                          data-cy="iiotNodeActionsAndInfoShowMoreLabels"
                          class="ml-n1"
                          >
                          mdi-chevron-down
                        </v-icon>
                      </div>
                      </v-list-item>
                  </v-list>
                </div>
              </template>
              <span>{{labels.toString()}}</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col
          lg="3"
          md="6"
          sm="8"
          xs="10"
          :class="{'pl-2  mt-5': !isMarginVisible, 'pl-16 pr-6 mt-3': isMarginVisible}"
        >
          <div
            class="node-information-text-light text--secondary pt-1"
          >
            {{ $t('nodes.information.lastStart') }}
          </div>
          <div
            class="node-information-text"
          >
            {{ selectedNode.lastSystemStart }}
          </div>
          <div
            class="node-information-text-light text--secondary pt-4"
          >
            {{ $t('nodes.information.created') }}
          </div>
          <div
            class="node-information-text"
          >
            {{ selectedNode.created }}
          </div>
        </v-col>
        <v-col
          lg="4"
          md="6"
          sm="8"
          cols="6"
          class="pr-6"
        >
        <v-row
          class="mt-9 pl-6 justify-center"
        >
          <v-col
            lg="4"
            md="3"
            cols="4"
            :class="{'pr-5': isMarginVisible}"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    v-if="canAccess('UI_NODE:DELETE')"
                    id="iiotNodeActionsAndInfoDeleteNode"
                    color="tttech-blue-button"
                    @click="deleteNode()"
                    fab
                    height="37"
                    width="37"
                    elevation="0"
                  >
                  <img
                    :src="`/img/DeleteNode.png`"
                  />
                  </v-btn>
                </div>
              </template>
              <span>{{ $t('nodes.information.deleteNode') }}</span>
            </v-tooltip>
          </v-col>
          <v-col
            :class="{'pr-2 pl-2': isMarginVisible, 'pl-3': !isMarginVisible}"
            lg="4"
            md="3"
            cols="4"
          >
            <node-reboot
              :selected-node="selectedNode"
            />
          </v-col>
          <v-col
            :class="{'pr-5 pl-2': isMarginVisible, 'pr-1': !isMarginVisible}"
            lg="4"
            md="3"
            cols="4"
          >
            <v-tooltip
              bottom
              class="pr-9"
            >
              <template v-slot:activator="{ on }">
                <div
                  v-on="on"
                  class="mb-14"
                >
                  <v-btn
                    id="iiotNodeActionsAndInfoConnect"
                    :disabled="!selectedNode.remoteConnections.length ||
                    !isNodeDataAvailable
                    || !canAccess('UI_REMOTE_CONN:CONNECT') || !selectedNode.isOnline()"
                    @click="connect"
                    fab
                    height="37"
                    width="37"
                    elevation="0"
                  >
                  <img
                    v-if=isConnectionDisabled
                    :src="`/img/link_icon_disabled.png`"
                  />
                  <img
                    v-else
                    :src="`/img/link_icon.png`"
                  />
                  </v-btn>
                </div>
              </template>
              <span>{{ $t('globals.connect') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        </v-col>
        <v-col
          lg="4"
          md="3"
          cols="2"
          class="pl-6 mb-6"
        >
        <v-row
        class="pl-5"
        >
          <capabilities
            :capabilities="selectedNode.capabilities"
            :text="'name'"
          />
        </v-row>
          <v-row>
            <video-output-configuration
              :selected-node="selectedNode"
            />
          </v-row>
          <v-row>
            <remote-connection-dialog
              v-if="selectedNode._id === selectedId"
              :node="selectedNode"
              :is-open-close-init-connection-dialog="isOpenCloseInitConnectionDialog"
              type="node"
              @cancel-event="closeRemoteConnectionWorkloadDialog"
            />
          </v-row>
        </v-col>
      </v-row>
    </div>
  </template>

<script>
import Capabilities from 'nerve-ui-components/components/capabilities/Capabilities.vue';
import NodeReboot from '@/components/nodes/node-reboot/NodeReboot.vue';
import VideoOutputConfiguration from '@/components/nodes/video-output-configuration/VideoOutputConfiguration.vue';
import remoteConnectionDialog from '@/components/remote-connection/remoteConnectionDialog.vue';

export default {
  components: {
    NodeReboot,
    VideoOutputConfiguration,
    Capabilities,
    remoteConnectionDialog,
  },
  props: {
    selectedNode: {
      type: Object,
      default: () => {},
    },
    userDetails: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      REQ_CONF_TIMEOUT: 60,
      logLevelList: {},
      nodeLogLevelDialog: false,
      initialObject: {},
      isMarginVisible: false,
    };
  },
  computed: {
    isOpenCloseInitConnectionDialog() {
      return this.$store.getters['remote-connection/isOpenCloseInitConnectionDialog'];
    },
    labels() {
      return this.selectedNode.transformedLabels ? this.selectedNode.transformedLabels.split(',') : this.selectedNode.transformedLabels;
    },
    isNodeDataAvailable() {
      return this.$store.getters['node-tree/isNodeDataAvailable'];
    },
    isConnectionDisabled() {
      return !this.selectedNode.remoteConnections.length
      || !this.isNodeDataAvailable || !this.canAccess('UI_REMOTE_CONN:CONNECT') || !this.selectedNode.isOnline();
    },
    selectedId() {
      return this.$store.getters['remote-connection/getSelectedNodeId'];
    },
  },
  methods: {
    closeRemoteConnectionWorkloadDialog() {
      this.$store.dispatch('remote-connection/set_selected_node_or_wl_id', { type: 'node', selectedId: '' });
      this.$store.dispatch('remote-connection/openCloseInitConnectionDialog', false);
    },
    deleteNode() {
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: 'nodes.information.deleteNode',
        subTitle: this.$t('nodes.information.deleteConfirmationTitle', { name: this.selectedNode.name, serialNumber: this.selectedNode.serialNumber }),
        callback: () => {
          this.$store.dispatch('nodes/remove', this.selectedNode);
        },
      });
    },
    closeNodeLogLevelDialog() {
      this.nodeLogLevelDialog = false;
    },
    connect() {
      this.$store.dispatch('remote-connection/fetch', this.selectedNode);
      this.$store.dispatch('remote-connection/set_selected_node_or_wl_id', { type: 'node', selectedId: this.selectedNode._id });
      this.$store.dispatch('remote-connection/openCloseInitConnectionDialog', true);
    },
    onResize() {
      this.isMarginVisible = window.innerWidth <= 1680;
    },
  },
};
</script>
  <style>
  .node-information-text-light{
    font-size: 12px;
  }
  .node-information-text{
    font-size: 14px;
  }
  .labels-expansion-panel *{
    padding: 0 !important;
  }
  .labels-expansion-panel-header {
    min-height: 16px !important;
    height: 16px !important;
  }
  .labels-expansion-panel .labels-expansion-panel-list {
    min-height: 16px !important;
    height: 16px !important;
  }
  .cut-text {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.5em;
    white-space: nowrap;
  }
  .div-labels {
    max-height: 8vh;
  }

  .icon-show-labels {
    padding-right: 70px;
    opacity: 0.5;
  }
  </style>
