<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <nerve-dialog
      id="iiotRemoteConnectionDialog"
      scrollable
      persistent
      max-width="600"
      :retain-focus="false"
      v-model="isOpenCloseInitConnectionDialog"
    >
      <v-card class="rc-dialog">
        <v-card-title
          class="text-center"
          v-if="type === 'node'">{{$t('remoteConnection.initConnectionTitle',
          { nodeWl: type, name: node.name })}}</v-card-title>
          <v-card-title v-if="type === 'workload'">{{$t('remoteConnection.initConnectionTitle',
          { nodeWl: type, name: workloadName })}}</v-card-title>
        <v-card-text class="text-left">
          <the-nerve-table
            :id="'iiotRemoteConnectionDialogRcTable'"
            :columns="columns"
            :empty-state="emptyListText || $t('remoteConnection.table.emptyList')"
            :is-action-menu-enabled="false"
            :is-search-enabled="false"
            :disable-pagination="true"
            :hide-footer="true"
            store-module="remote-connection"
            @row-clicked="params => connect(params.item)"
          />
        </v-card-text>

        <v-card-actions>
          <nerve-button
            id="iiotRemoteConnectionDialogButtonClose"
            :text="$t('baseForm.cancelBtn')"
            type-of-btn="cancel"
            size="normal"
            @click-event="cancel"
          />
        </v-card-actions>
      </v-card>
    </nerve-dialog>
    <dialog-for-establish-connection :name="rcName"/>
  </div>

</template>

<script>
import { NerveButton, TheNerveTable, NerveDialog } from 'nerve-ui-components';
import dialogForEstablishConnection from '@/components/remote-connection/dialogForEstablishConnection.vue';
import mqtt from '@/plugins/mqtt';

export default {
  components: {
    dialogForEstablishConnection,
    TheNerveTable,
    NerveButton,
    NerveDialog,
  },
  props: {
    node: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
    workloadId: {
      type: String,
      default: '',
    },
    versionId: {
      type: String,
      default: '',
    },
    workloadName: {
      type: String,
      default: '',
    },
    nodeSerial: {
      type: String,
      default: '',
    },
    isOpenCloseInitConnectionDialog: {
      type: Boolean,
      default: false,
    },
    emptyListText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rcName: '',
    };
  },
  computed: {
    columns() {
      return [
        ...(this.type === 'workload' && this.getWorkloadType === 'docker-compose') ? [
          {
            text: this.$t('remoteConnection.serviceNameColumn'),
            value: 'serviceName',
            sortable: false,
          },
        ] : [],
        {
          text: this.$t('remoteConnection.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('remoteConnection.type'),
          value: 'type',
          sortable: false,
        },
        {
          text: this.$t('remoteConnection.port'),
          value: 'port',
          sortable: false,
        },
        {
          text: this.$t('remoteConnection.approval'),
          value: 'acknowledgment',
          sortable: false,
        },
      ];
    },
    getWorkloadType() {
      return this.$store.getters['remote-connection/getWorkloadType'];
    },
  },
  methods: {
    cancel() {
      this.rcName = '';
      this.$emit('cancel-event');
    },

    async connect(item) {
      this.rcName = item.name;
      await this.$store.dispatch('remote-connection/openCloseConnectingDialog', true);
      const rc = {
        acknowledgment: item.acknowledgment,
        connection: item.connection,
        name: item.name,
        port: item.port,
        serialNumber: this.node && this.node.serialNumber
          ? this.node.serialNumber : this.nodeSerial,
        type: item.type,
        versionId: this.versionId || '',
        workloadId: this.workloadId || '',
        _id: item._id,
      };
      try {
        // Subscribing to the mqtt-topic prior to sending the API request - NERVESW-9868
        await mqtt.subscribeTo('initRc');
        await this.$store.dispatch('remote-connection/remoteConnect', rc);
      } catch (err) {
        this.$store.dispatch('remote-connection/openCloseConnectingDialog', false);
      }
    },
  },
};

</script>

<style scoped>
  .max-height {
    overflow-y: auto;
    max-height: 600px;
  }
  .rc-dialog {
    max-height: 460px !important;
    min-height: 320px !important;
  }
</style>
