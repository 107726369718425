<template>
  <div
    v-resize="onResize">
    <v-row>
      <v-col
        lg="5"
        md="7"
        sm="9"
        xs="12">
        <div class="mb-5 font-size-18">
          {{ $t('nodes.dna.targetConfiguration') }}
        </div>
        <div class="font-size-16 text--secondary">
          {{ targetConfiguration.fileName }}
        </div>
        <div class="mb-4 font-size-12 text--disabled">
          {{ $t('nodes.dna.lastDNAStateChange') }}: {{ targetConfiguration.updated }}
        </div>
        <div class="mb-9">
          <Status
            :status="targetConfiguration.status"
          />
        </div>
        <v-row class="mb-2">
          <v-col>
            <div class="d-flex flex-row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    v-if=isDownloadReApplyUploadDisabled()
                    id="iiotDnaDownloadTargetConfigurationDisabledButton"
                    :src="`/img/download_disabled.svg`"
                    v-bind="attrs"
                    max-height="33px"
                    min-height="33px"
                    min-width="33px"
                    max-width="33px"
                    class="cursor-pointer"
                    v-on="on"
                  />
                  <v-img
                    v-else
                    id="iiotDnaDownloadTargetConfigurationButton"
                    :src="`/img/download.svg`"
                    v-bind="attrs"
                    max-height="33px"
                    min-height="33px"
                    min-width="33px"
                    max-width="33px"
                    class="cursor-pointer"
                    v-on="on"
                    @click="downloadTargetConfiguration"
                  />
                </template>
                <span>{{ $t(`nodes.dna.downloadTargetConfiguration`) }}</span>
              </v-tooltip>
                <nerve-button
                id="iiotDnaReApplyTargetConfigurationButton"
                :text="$t('baseForm.reApply')"
                class="mr-5 ml-5"
                type-of-btn="action"
                size="small"
                :disabled=isDownloadReApplyUploadDisabled()
                @click-event="reApplyTargetConfiguration()"
              />
              <nerve-button
                id="iiotDnaCancelConfigurationButton"
                :text="$t('baseForm.cancelBtn')"
                type-of-btn="action"
                size="small"
                @click-event="cancelApplyConfiguration()"
                :disabled=isCancelDisabled()
              />
            </div>

          </v-col>
        </v-row>
        <v-divider class="mt-6 mb-7"/>
        <v-row>
          <v-col :class="{'mb-16' : isDividersVisible}">
            <div class="mb-3 font-size-18">
              {{ $t('nodes.dna.applyNewTargetConfiguration') }}
            </div>
            <div>
              <!-- UPLOAD FILE -->
              <v-row>
                <v-col
                  lg="9"
                  md="10"
                  sm="10"
                  xs="10">
                  <v-text-field
                    id="iiotDnaUploadTargetConfigurationTextField"
                    v-model="targetConfigurationFile.name"
                    :placeholder="$t('nodes.dna.targetConfigurationLabel')"
                    disabled
                  />
                </v-col>
                <v-col
                  lg="2"
                  class="pa-0">
                  <v-btn
                    id="iiotDnaUploadTargetConfigurationBtn"
                    light
                    color="primary"
                    class="upload-button-size mt-6"
                    small
                    fab
                    :disabled=isDownloadReApplyUploadDisabled()
                    @click="$refs.dnaUploadTargetConfigurationInput.click()"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          class="white--text"
                          v-on="on"
                        >
                          arrow_upward
                        </v-icon>
                      </template>
                      <div>{{ $t('nodes.dna.uploadTargetConfiguration') }}</div>
                    </v-tooltip>
                  </v-btn>
                  <input
                    id="iiotDnaUploadTargetConfigurationInput"
                    ref="dnaUploadTargetConfigurationInput"
                    type="file"
                    style="display:none"
                    :accept="allowedExtensionsForTargetConfiguration"
                    @click="clearInput"
                    @change="importFile"
                  >
                </v-col>
              </v-row>
            </div>
            <nerve-button
              id="iiotDnaApplyConfigurationButton"
              :text="$t('baseForm.applyConfiguration')"
              type-of-btn="action"
              size="small"
              class="mt-3"
              :disabled=isApplyDisabled()
              @click-event="applyConfiguration()"
            />
          </v-col>
        </v-row>
        <v-divider
          v-if="!isDividersVisible"
          class="mt-10 mb-2"/>
      </v-col>
      <v-divider
        v-if="isDividersVisible"
        vertical
        class="mr-5"/>
      <v-col
        lg="4"
        md="7"
        sm="9"
        xs="12">
        <div class="mb-6 font-size-18">
          {{ $t('nodes.dna.currentConfiguration') }}
        </div>
        <div class="d-flex flex-row mb-6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-if=isDownloadReApplyUploadDisabled()
                id="iiotDnaDownloadCurrentConfigurationDisabledButton"
                :src="`/img/download_disabled.svg`"
                v-bind="attrs"
                max-height="33px"
                min-height="33px"
                min-width="33px"
                max-width="33px"
                class="mt-1 mr-3 cursor-pointer"
                v-on="on"
              />
              <v-img
                v-else
                id="iiotDnaDownloadCurrentConfigurationButton"
                :src="`/img/download.svg`"
                v-bind="attrs"
                max-height="33px"
                min-height="33px"
                min-width="33px"
                max-width="33px"
                class="mt-1 mr-3 cursor-pointer"
                v-on="on"
                @click="downloadCurrentConfiguration"
              />
            </template>
            <span>{{ $t(`nodes.dna.downloadCurrentConfiguration`) }}</span>
          </v-tooltip>

          <div class="font-size-12 text--disabled">
            {{ $t('nodes.dna.currentConfigurationInfo') }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
/*
  imports
*/
import { ref, computed, watch } from 'vue';
import { NerveButton } from 'nerve-ui-components';
import store from '@/store';
import Status from '@/components/nodes/dna/status';
import { useStoreDna } from '@/store/modules/pinia/dna';
import Logger from '@/utils/logger';
import { DNA_STATUSES } from '@/constants';

/*
  props
*/
const props = defineProps({
  serialNumber: {
    type: String,
    required: true,
  },
  connectionStatus: {
    type: String,
    required: true,
  },
});

/*
  store
*/
const storeDna = useStoreDna();

/*
  target configuration
*/
const targetConfigurationFile = ref({});
const allowedExtensionsForTargetConfiguration = ref(['.yaml', '.zip']);
const dnaUploadTargetConfigurationInput = ref(null);

const targetConfiguration = computed(() => storeDna.getTargetConfiguration);

watch(targetConfiguration, (newValue) => {
  if (newValue.status === DNA_STATUSES.applied) {
    targetConfigurationFile.value = '';
  }
});

watch(() => props.serialNumber, () => {
  targetConfigurationFile.value = '';
});

const downloadTargetConfiguration = async () => {
  await storeDna.downloadTargetConfiguration(props.serialNumber);
};

const reApplyTargetConfiguration = () => {
  if (window.localStorage.getItem('userDetails')
    && JSON.parse(localStorage.getItem('userDetails')).confirmDialog?.reApplyTargetConfiguration) {
    storeDna.reApplyTargetConfiguration(props.serialNumber);
    return;
  }
  store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
    title: 'nodes.dna.confirmationDialogTitle',
    subTitle: 'nodes.dna.confirmationDialogSubtitle',
    callback: async () => {
      try {
        storeDna.reApplyTargetConfiguration(props.serialNumber);
      } catch (e) {
        Logger.error(e);
      }
    },
    dontAskMeAgainCallback: async () => {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      userDetails.confirmDialog = userDetails.confirmDialog || {};
      userDetails.confirmDialog.reApplyTargetConfiguration = true;
      window.localStorage.setItem('userDetails', JSON.stringify(userDetails));
    },
  });
};

const clearInput = (e) => {
  e.target.value = '';
};

const importFile = (e) => {
  if (e?.target?.files?.length === 0) {
    return;
  }
  const fileExtension = e.target.files[0].name.split('.').pop();
  const isAllowedExtension = allowedExtensionsForTargetConfiguration.value.includes(`.${fileExtension}`);
  if (!isAllowedExtension) {
    store.dispatch('utils/_api_request_handler/show_custom_toast', {
      text: 'nodes.dna.wrongFileExtension',
      textParameters: allowedExtensionsForTargetConfiguration.value.join(', '),
      color: 'red',
      showClose: true,
    });
    return;
  }
  // eslint-disable-next-line prefer-destructuring
  targetConfigurationFile.value = e.target.files[0];
};

const applyConfiguration = () => {
  const formData = new FormData();
  formData.append('file', targetConfigurationFile.value);
  storeDna.applyConfiguration({ serialNumber: props.serialNumber, formData });
};

const cancelApplyConfiguration = () => {
  storeDna.cancelApplyConfiguration(props.serialNumber);
};

const isDownloadReApplyUploadDisabled = () => targetConfiguration.value.status
  === DNA_STATUSES.canceling
  || targetConfiguration.value.status === DNA_STATUSES.reconfiguring
  || targetConfiguration.value.status === DNA_STATUSES.n_a;

const isApplyDisabled = () => !targetConfigurationFile.value.name
  || targetConfiguration.value.status === DNA_STATUSES.canceling
  || targetConfiguration.value.status === DNA_STATUSES.reconfiguring
  || targetConfiguration.value.status === DNA_STATUSES.n_a;

const isCancelDisabled = () => targetConfiguration.value.status === DNA_STATUSES.applied
  || targetConfiguration.value.status === DNA_STATUSES.canceling
  || targetConfiguration.value.status === DNA_STATUSES.modified
  || targetConfiguration.value.status === DNA_STATUSES.undefined
  || targetConfiguration.value.status === DNA_STATUSES.n_a;

/*
  current configuration
*/

const downloadCurrentConfiguration = async () => {
  await storeDna.downloadCurrentConfiguration(props.serialNumber);
};

/*
  resize window
*/
const isDividersVisible = ref(true);

const onResize = async () => {
  isDividersVisible.value = window.innerWidth > 1264;
};

</script>

<style>
  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 30px;
    padding:
     0;
  }

  .upload-button-size {
    height: 33px !important;
    width: 33px !important;
  }

</style>
