<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <nerve-dialog
      :key="dialogState.id || dialogState.parentId"
      v-model="dialogState.isShown"
      scrollable
      max-width="500"
      @keydown.enter.prevent="save()"
    >
      <v-card class="tree-node-dialog">
        <v-card-title>{{ $t('nodes.tree.addLocation') }}</v-card-title>
        <v-card-text>
          <v-text-field
            id="iiotNodeTreeFolderDialogName"
            ref="name"
            v-model="folderName"
            :rules="[rules.required, rules.name, rules.minLocationLength, rules.maxLocationLength]"
            :label="$t('nodes.tree.location')"
            maxlength="40"
            autofocus
          />
        </v-card-text>
        <v-card-actions>
          <nerve-button
            id="iiotNodeTreeFolderDialogCancel"
            :text="$t('baseForm.cancelBtn')"
            type-of-btn="cancel"
            size="normal"
            class="mr-2"
            ref="closeBtn"
            @click-event="closeDialog"
          />
          <nerve-button
            id="iiotNodeTreeFolderDialogSave"
            :text="$t('baseForm.saveBtn')"
            :disabled="!valid"
            type-of-btn="action"
            size="normal"
            ref="mergeBtn"
            @click-event="save"
          />
        </v-card-actions>
      </v-card>
    </nerve-dialog>
  </v-form>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';
import { VALIDATION_REGEX, TREE_NODE_FOLDER_NAME } from '@/constants';
import NodeTreeModel, { TREE_NODE_TYPES } from '@/model/node-tree/node-tree.model';

export default {
  name: 'AddEditTreeNodeDialog',
  components: { NerveButton, NerveDialog },
  data() {
    return {
      valid: false,
      folderName: '',
    };
  },

  watch: {
    // eslint-disable-next-line func-names
    'dialogState.isShown': function (visible) {
      if (visible) {
        this.folderName = (this.treeNode && this.treeNode.data && this.treeNode.data.name) || '';
      }
    },
  },

  computed: {
    dialogState: {
      get() {
        return this.$store.getters['node-tree/getDialogState'];
      },
      set(value) {
        this.$store.dispatch('node-tree/toggle_add_edit_dialog', { isShown: value });
      },
    },
    treeNode() {
      return this.$store.getters['node-tree/getNodeById'](this.dialogState.id)
        || new NodeTreeModel({
          name: '',
          parentId: this.dialogState.parentId,
          type: TREE_NODE_TYPES.FOLDER,
        });
    },
    rules() {
      return {
        required: (v) => !!v || this.$t('baseForm.required'),
        name: (v) => VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(v) || this.$t('users.addEditUser.nameRegexMsg'),
        minLocationLength: () => this.folderName.length >= TREE_NODE_FOLDER_NAME.MIN_LENGTH || this.$t('nodes.tree.locationNameMinLengthMsg', { msgLength: TREE_NODE_FOLDER_NAME.MIN_LENGTH }),
        maxLocationLength: () => this.folderName.length <= TREE_NODE_FOLDER_NAME.MAX_LENGTH || this.$t('nodes.tree.locationNameMaxLengthMsg', { msgLength: TREE_NODE_FOLDER_NAME.MAX_LENGTH }),
      };
    },
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('node-tree/toggle_add_edit_dialog', { isShown: false });
      this.folderName = '';
    },

    save() {
      const action = this.treeNode.data.id ? 'update' : 'create';
      this.treeNode.data.name = this.folderName;
      this.$store.dispatch(`node-tree/${action}`, this.treeNode);
      this.closeDialog();
    },
  },

};
</script>
<style lang="scss" scoped>
  .tree-node-dialog {
    max-height: 460px !important;
    min-height: 320px !important;
  }
</style>
