<!-- eslint-disable vue/require-valid-default-prop -->
<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

  <template>
    <v-form
      ref="nodeInformationLogLevel"
      eager
    >
      <v-col
        col="12"
        md="12"
        s="6"
        xs="6"
        class="pl-1 mt-4"
        v-if="monitoringAndLoggingStatus.logLevels"
      >
        <v-row>
          <span
            class="node-information-text ml-3">
            {{ $t("nodes.information.logLevels") }}
          </span>
        </v-row>
        <v-row>
          <v-col
            col="3"
          >
            <v-select
              v-if="getOvdmLevels && (monitoringAndLoggingStatus &&
               monitoringAndLoggingStatus.logLevels
              && monitoringAndLoggingStatus.logLevels.values &&
              monitoringAndLoggingStatus.logLevels.values.ovdm
              && monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm
              && monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level)"
              v-model="monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level"
              :label="$t('nodes.information.nodeManagement')"
              :items="logLevelList"
              autocomplete="autocomplete"
              attach
              @change="changeMonitoringAndLoggingSetting()"
            />
          </v-col>
          <v-col
            col="9"
          ></v-col>
        </v-row>
        <v-row>
          <v-col
            col="3"
            v-if="logLevelListLib.length !== 0 && (monitoringAndLoggingStatus &&
            monitoringAndLoggingStatus.logLevels
            && monitoringAndLoggingStatus.logLevels.values &&
             monitoringAndLoggingStatus.logLevels.values.libvirt
            && monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl
            && monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl.level)"
          >
            <v-select
              v-model="monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl.level"
              :items="logLevelListLib"
              :label="$t('nodes.information.virtualization')"
              autocomplete="autocomplete"
              attach
              @change="changeMonitoringAndLoggingSetting()"
            />
          </v-col>
          <v-col
            col="9"
          ></v-col>
        </v-row>
        <v-row
        >

          <v-col
            col="3"
            v-if="logLevelListCodesys.length !== 0 && (monitoringAndLoggingStatus &&
            monitoringAndLoggingStatus.logLevels
            && monitoringAndLoggingStatus.logLevels.values &&
            monitoringAndLoggingStatus.logLevels.values.codesys &&
            monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl &&
            monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl.level)"
          >
            <v-select
              v-model="monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl.level"
              :items="logLevelListCodesys"
              :label="$t('nodes.information.codesys')"
              autocomplete="autocomplete"
              attach
              @change="changeMonitoringAndLoggingSetting()"
              />
          </v-col>
          <v-col
            col="9"
          ></v-col>
        </v-row>
        <v-row>
          <span
            class="node-information-text ml-3">
            {{ $t("nodes.information.setTime") }}
          </span>
        </v-row>
        <v-row>
          <v-col
            md="2"
            sm="2"
          >
            <v-text-field
              id="iiotNodeInformationLogLevelTimeHours"
              v-model="time.hours"
              label="hours"
              :rules="[rules.min_hours, rules.max_hours]"
              :min="minHours"
              :max="maxHours"
              class="default-input-appearance"
              type="number"
            />
          </v-col>
          <span class="colon mt-7"> : </span>
          <v-col
            md="2"
            sm="2"
          >
            <v-text-field
              id="iiotNodeInformationLogLevelTimeMinutes"
              v-model="time.minutes"
              label="minutes"
              :rules="[rules.min_minutes, rules.max_minutes]"
              :min="minMinutes"
              :max="maxMinutes"
              class="default-input-appearance"
              type="number"
            />
          </v-col>
          <v-col>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-img
                  id="iiotNodeInformationLogLevelInformationImg"
                  src="/resources/images/information.svg"
                  class="mt-6"
                  v-on="on"
                />
              </template>
              <span>{{ $t('nodeLogLevel.timeDescription') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <span
          class="text--secondary node-information-text-light">
          {{ $t("nodes.information.timeDescription") }}
        </span>
      </v-col>
    </v-form>
  </template>

<script>
import { MINUTES, HOURS } from '@/constants';

export default {
  props: {
    logLevelList: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    logLevelListLib: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    logLevelListCodesys: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    serialNumber: {
      type: String,
      default: '',
    },
    nodeVersion: {
      type: String,
      default: '',
    },
    initialObject: {
      type: Object,
      default: () => ({}),
    },
    isNodeLogLevelDialog: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      minMinutes: MINUTES.MIN_LENGTH,
      minHours: HOURS.MIN_LENGTH,
      maxMinutes: MINUTES.MAX_LENGTH,
      maxHours: HOURS.MAX_LENGTH,
      setToAllLogLevel: '',
      notCorrectResponse: true,
      nodeNewVersion: false,
      responseObject: {},
      version: '2.1.2',
      ovdmValues: {
        0: 'verbose',
        3: 'error',
        4: 'warn',
        6: 'info',
        7: 'debug',
        8: 'silly',
      },
      MINUTES_PER_HOUR: 60,
      MILISECONDS_PER_MINUTE: 60000,
      REQ_CONF_TIMEOUT: 60,
      TIMEOUT: 120000,
      progressBar: false,
      newNodeSetToAllList: [],
      setToAllOvdmValues: {
        0: '0',
        1: '3',
        2: '3',
        3: '3',
        4: '4',
        5: '6',
        6: '6',
        7: '7',
        8: '8',
      },
      list: {},
      newList: [],
      isChanged: false,
    };
  },
  computed: {
    rules() {
      return {
        max_hours: (v) => (v <= HOURS.MAX_LENGTH || this.$t('nodeLogLevel.maxHours')),
        min_minutes: (v) => (v >= MINUTES.MIN_LENGTH || this.$t('nodeLogLevel.minMinutes')),
        min_hours: (v) => (v >= HOURS.MIN_LENGTH || this.$t('nodeLogLevel.minHours')),
        max_minutes: (v) => (v <= MINUTES.MAX_LENGTH || this.$t('nodeLogLevel.maxMinutes')),
      };
    },
    getOvdmLevels() {
      return this.$store.getters['nodes/getLogLevelList'];
    },
    selectedNodeManagement() {
      return this.$store.getters['nodes/getLogLevelList'];
    },
    monitoringAndLoggingStatus() {
      return this.$store.getters['nodes/getMonitoringAndLoggingSettings'];
    },
    initialMonitoringAndLogging() {
      return this.$store.getters['nodes/getInitialLogLevelList'].values;
    },
    initialValues() {
      return this.$store.getters['nodes/getMonitoringAndLoggingSettingsInitialValues'];
    },
  },
  created() {
    this.list = this.logLevelList;
    if (this.nodeVersion !== '') {
      this.init();
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'time.hours': function (newValue, oldValue) {
      if (newValue !== oldValue && this.$refs.nodeInformationLogLevel.validate()) {
        this.$store.dispatch('nodes/set_log_system_save_enabled', { isChangedLogLevel: true });
        this.timeout = (this.time.hours * this.MINUTES_PER_HOUR
          + this.time.minutes) * this.MILISECONDS_PER_MINUTE;
      }
      if (!this.$refs.nodeInformationLogLevel.validate()) {
        this.$store.dispatch('nodes/disable_log_system_button', this.$store.dispatch('nodes/disable_log_system_button', { isChangedLogLevel: false }));
      }
    },
    // eslint-disable-next-line func-names
    'time.minutes': function (newValue, oldValue) {
      if (newValue !== oldValue && this.$refs.nodeInformationLogLevel.validate()) {
        this.$store.dispatch('nodes/set_log_system_save_enabled', { isChangedLogLevel: true });
      }
      if (!this.$refs.nodeInformationLogLevel.validate()) {
        this.$store.dispatch('nodes/disable_log_system_button', this.$store.dispatch('nodes/disable_log_system_button', { isChangedLogLevel: false }));
      }
    },
  },
  methods: {
    init() {
      if (this.nodeVersion) {
        this.nodeNewVersion = this.checkNodeVersion();
      }
      if (this.nodeNewVersion) {
        this.responseObject = this.getOvdmLevels;
      }
    },
    changeMonitoringAndLoggingSetting() {
      const lib_level = this.initialMonitoringAndLogging.libvirt.libvirt_ctl.level;
      const lib_options = this.initialMonitoringAndLogging.libvirt.libvirt_ctl.options;
      // eslint-disable-next-line radix
      const parse_level = parseInt(lib_level);
      const found_lib = lib_options.find((option) => option.value === parse_level);
      if (found_lib) {
        this.initialMonitoringAndLogging.libvirt.libvirt_ctl.level = found_lib.nodeEq;
      }
      if (this.initialMonitoringAndLogging.codesys) {
        const codesys_level = this.initialMonitoringAndLogging.codesys.codesys_ctl.level;
        const codesys_options = this.initialMonitoringAndLogging.codesys.codesys_ctl.options;
        // eslint-disable-next-line radix
        const parse_level1 = parseInt(codesys_level);
        const found_codesys = codesys_options.find((option) => option.value === parse_level1);
        if (found_codesys) {
          this.initialMonitoringAndLogging.codesys.codesys_ctl.level = found_codesys.nodeEq;
        }
      }

      this.isChanged = ((this.monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level
      !== this.initialMonitoringAndLogging.ovdm.ovdm.level)
      || (this.monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl.level
      !== this.initialMonitoringAndLogging.libvirt.libvirt_ctl.level)
      || (this.monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl.level
      !== this.initialMonitoringAndLogging.codesys.codesys_ctl.level));

      if (this.isChanged) {
        this.$store.dispatch('nodes/set_log_system_save_enabled', { isChangedLogLevel: this.isChanged });
      } else {
        this.$store.dispatch('nodes/disable_log_system_button', { isChangedLogLevel: this.isChanged });
      }
    },
    checkNodeVersion() {
      const nodeV = this.nodeVersion.split('.');
      const v = this.version.split('.');
      // eslint-disable-next-line radix
      if (parseInt(nodeV[0]) > parseInt(v[0])) {
        return true;
      }
      // eslint-disable-next-line radix
      if (parseInt(nodeV[0]) === parseInt(v[0]) && parseInt(nodeV[1]) > parseInt(v[1])) {
        return true;
      }
      // eslint-disable-next-line radix
      if (parseInt(nodeV[0]) === parseInt(v[0])
        // eslint-disable-next-line radix
        && parseInt(nodeV[1]) === parseInt(v[1]) && parseInt(nodeV[2]) > parseInt(v[2])) {
        return true;
      }
      return false;
    },
  },
};
</script>
  <style lang="scss" scoped>
    .node-information-text {
      font-size: 16px;
      font-weight: 67% !important;
    }
    .node-information-text-light {
      font-size: 13px;
    }
  </style>
