<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <div
    v-if="selectedNode.id && canAccess('NODE:VIEW')"
    class="mt-4 d-flex">
    <div
      v-if="isDnaSupported"
      class="mt-4">
      <v-tooltip
        v-if="dnaStatus.status === 'n/a'"
        bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            :id="`iiotNodeInformationDna${dnaStatus.status}StatusImage`"
            :src="`/img/dna_status_offline.svg`"
            v-bind="attrs"
            max-height="25px"
            min-height="25px"
            min-width="19.2px"
            max-width="19.2px"
            class="cursor-pointer"
            v-on="on"
          />
        </template>
        <span>{{ $t(`nodes.dna.dna`) }}</span>
        <span>{{ $t(`nodes.dna.${dnaStatus.status}`) }}</span>
      </v-tooltip>
      <v-tooltip
        v-else
        bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            :id="`iiotNodeInformationDna${dnaStatus.status}StatusImage`"
            :src="`/img/dna_status_${dnaStatus.status}.svg`"
            v-bind="attrs"
            max-height="25px"
            min-height="25px"
            min-width="19.2px"
            max-width="19.2px"
            class="cursor-pointer"
            :class="[
              {'fading' : dnaStatus.status === DNA_STATUSES.canceling},
              {'fading' : dnaStatus.status === DNA_STATUSES.reconfiguring},
            ]"
            v-on="on"
          />
        </template>
        <span>{{ $t(`nodes.dna.dna`) }}</span>
        <span>{{ $t(`nodes.dna.${dnaStatus.status}`) }}</span>
      </v-tooltip>
    </div>
    <v-container>
      <v-row
      >
        <v-col
          class="title pr-0 text-left"
        >
          <div class="title d-inline-flex align-">
            <h1
              id="iiotNodeInformationNodeName"
              data-cy="iiotNodeInformationNodeName"
            >{{ selectedNode.name }}</h1>
            <h6 class="font-weight-light ml-2">
              [{{ selectedNode.serialNumber }}]
            </h6>
          </div>
          <v-divider class="mb-0" />
          <h6
            v-if="selectedNode.currentFWVersion"
            class="font-weight-light"
          >
            {{ selectedNode.currentFWVersion }}
          </h6>
        </v-col>
      </v-row>
      <v-row>
        <v-tabs
          v-model="newTab"
          class="pb-8"
        >
          <v-tab
            id="iiotNodeInformationOverviewTab"
            data-cy="iiotNodeInformationOverviewTab"
            autofocus
            :key="overview"
            @click="tabChanged(0)"

          >
            <h4> {{ $t('nodes.tree.overviewTab') }}</h4>
          </v-tab>
          <v-tab
            v-if="canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')"
            id="iiotNodeInformationLogsTab"
            data-cy="iiotNodeInformationLogsTab"
            @click="newTab = 1; monitoringAndLoggingSetting()"
            :key="logs"
          >
            <h4> {{ $t('nodes.tree.logsTab') }}</h4>
          </v-tab>
          <v-tab
            v-if="canAccess('UI_NODE:DNA') && isDnaSupported"
            id="iiotNodeInformationDnaTab"
            :key="dna"
          >
            <h4> {{ $t('nodes.dna.dnaTab') }}</h4>
          </v-tab>
        </v-tabs>
      </v-row>
      <v-tabs-items
        v-model="newTab"
      >
    <v-tab-item
      :key="overview">
      <div
        v-resize="onResize"
      >
        <v-row>
          <v-col
            lg="7"
            cols="12"
          >
            <node-actions-and-info
              :user-details="userDetails"
              :selected-node="selectedNode"
              @connections-btn-clicked="$emit('connections-btn-clicked', selectedNode)"
            />
          </v-col>
          <v-col
            lg="5"
            cols="12"
            class="pt-0"
            :class="{'mt-10 pt-0': isMarginVisible}"
          >
            <node-status-bars :selected-node="selectedNode" />
          </v-col>
        </v-row>
        <v-row
          v-if="canAccess('UI_WORKLOAD_CONTROL:LIST') && loadingTable"
          cols="12"
          :class="{'pt-10': isMarginVisible}"
          class="justify-end mt-12 mb-1 pr-3"
        >
          <v-img
            id="iiotNodeInformationTypeStatusTable"
            :src="tableView ? `/img/table-view-blue.svg` : `/img/table-view-silver.svg`"
            :lazy-src="tableView ? `/img/table-view-blue.svg` : `/img/table-view-silver.svg`"
            class="pointer"
            max-height="18px"
            max-width="19px"
            @click="iconView = false; tableView=true; setIconTableView()"
          />
          <v-img
            id="iiotNodeInformationTypeStatusIcon"
            :src="iconView ? `/img/icon-view-blue.svg` : `/img/icon-view-silver.svg`"
            :lazy-src="iconView ? `/img/icon-view-blue.svg` : `/img/icon-view-silver.svg`"
            max-height="18px"
            max-width="19px"
            class="ml-3 pointer"
            @click="iconView = true; tableView=false; setIconTableView()"
          />
        </v-row>
        <v-row>
          <v-col
            v-if="canAccess('UI_WORKLOAD_CONTROL:LIST') && iconView"
            cols="12"
            class="node-devices-scroll"
          >
            <node-devices
              :devices="selectedNode.devices"
              @deployed-workload-clicked="workload=>
              goToWorkloadControlPage( {node: selectedNode, workload })"
              class="pt-6"
            />

          </v-col>
          <v-col
            v-if="canAccess('UI_WORKLOAD_CONTROL:LIST') && tableView"
            cols='12'
            class="mt-n16"
          >
            <nerve-simple-table
              v-if="loadingTable"
              id="Workloads"
              :headers="nodeVersionInfo ? nerveSimpleTableHeadersOlderVersionOfNode
                : nerveSimpleTableHeaders"
              :items="selectedNode.devices"
              :empty-state="$t('nerveSimpleTable.empty')"
              @st-row-clicked="workload=>
              goToWorkloadControlPage( {node: selectedNode, workload })"
            />
            <v-row
              v-if="nodeVersionInfo && this.selectedNode.devices.length && loadingTable"
              class="ml-1 mt-2 mb-1"
              style="opacity: 80%; font-size: 12px;">
              {{ $t('nerveSimpleTable.nodeVersionInfo') }}
            </v-row>
          </v-col>
          <div
            v-if="canAccess('UI_WORKLOAD_CONTROL:LIST') && !loadingTable &&
            selectedNode.connectionStatus === 'online'"
            class="mb-7 ml-3 text--disabled"
            id="iiotNodeInformationMessage"
            data-cy="iiotNodeInformationMessage"
          >
            {{ $t('nerveSimpleTable.noWlDeployed') }}
          </div>
        </v-row>
      </div>
    </v-tab-item>
    <v-tab-item
      v-if="canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')"
      :key="logs"
    >
      <v-form
        ref="nodeInformationLogTab"
      >
      <v-row
      >
        <v-col
          lg="5"
        >
        <node-information-view-system
         :selected-node="selectedNode"
         :filebeat-error="filebeatError"
        />
        <node-information-log-level
          v-if="selectedNode.isOnline() && canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS') &&
            canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS') && !filebeatError"
          :selected-node="selectedNode"
          :node-version="selectedNode.currentFWVersion"
          :log-level-list="logLevelList"
          :log-level-list-codesys="logLevelListCodesys"
          :log-level-list-lib="logLevelListLib"
          :time="time"
        />
      </v-col>
      <v-divider vertical />
      <v-col
        lg="5">
        <node-information-docker-system
          :selected-node="selectedNode"
          :filebeat-error="filebeatError"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="filebeatError && selectedNode.isOnline()"
      class="mt-6 mb-0 ml-1 text--secondary"
      >
      {{ $t('errorMessages.nerve_node_027') }}
      </v-row>
    <div
      class="d-flex justify-end pr-4"
    >
      <nerve-button
        v-if="selectedNode.isOnline() && !filebeatError"
        id="iiotNodeInformationDiscardChangesBtn"
        data-cy="iiotNodeInformationDiscardChangesBtn"
        :text="$t('workloadDetail.discardChanges')"
        :disabled="!(isViewSystemSaveEnabled || isDockerSystemSaveEnabled || isLogLevelSaveEnabled)"
        type-of-btn="cancel"
        size="normal"
        class="mr-5"
        autofocus
        @click-event="cancel()"
      />
      <nerve-button
        v-if="selectedNode.isOnline() && !filebeatError"
        id="iiotNodeInformationUpdateBtn"
        data-cy="iiotNodeInformationUpdateBtn"
        :text="$t('workloadDetail.save')"
        :disabled="!(isViewSystemSaveEnabled || isDockerSystemSaveEnabled || isLogLevelSaveEnabled)"
        type-of-btn="action"
        size="normal"
        @click-event="submitForm()"
      />
    </div>
    </v-form>
    </v-tab-item>
    <v-tab-item
      v-if="canAccess('UI_NODE:DNA') && isDnaSupported"
      :key="dna"
    >
      <Dna
        :serialNumber = selectedNode.serialNumber
        :connectionStatus="selectedNode.connectionStatus"
      />
    </v-tab-item>
    </v-tabs-items>
  </v-container>
    </div>
  </template>

<script>
import { NerveSimpleTable } from 'nerve-ui-components';
import NerveButton from 'nerve-ui-components/components/NerveButton.vue';
import { debounce } from 'lodash';
import { NODE_MODELS } from '@/model/node/node.model';
import NodeStatusBars from '@/components/nodes/node-information/helpers/NodeStatusBars.vue';
import NodeActionsAndInfo from '@/components/nodes/node-information/helpers/NodeActionsAndInfo.vue';
import NodeDevices from '@/components/nodes/node-information/helpers/NodeDevices.vue';
import shared from '@/helpers/shared';
import NodeInformationViewSystem from './helpers/NodeInformationViewSystem.vue';
import NodeInformationDockerSystem from './helpers/NodeInformationDockerSystem.vue';
import NodeInformationLogLevel from './helpers/NodeInformationLogLevel.vue';
import Logger from '@/utils/logger';
import Dna from '@/components/nodes/dna/dna';
import { useStoreDna } from '@/store/modules/pinia/dna';
import { DNA_STATUSES } from '@/constants';

const storeDna = useStoreDna();

export default {
  components: {
    NodeStatusBars,
    NodeActionsAndInfo,
    NodeDevices,
    NodeInformationLogLevel,
    NerveSimpleTable,
    NodeInformationViewSystem,
    NodeInformationDockerSystem,
    NerveButton,
    Dna,
  },
  props: {
    userDetails: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      NODE_MODELS,
      isMarginVisible: false,
      iconView: false,
      tableView: true,
      username: '',
      nodeVersionInfo: false,
      isDnaSupported: false,
      loadingTable: false,
      overview: 0,
      logs: 1,
      dna: 2,
      tab: null,
      isUpdate: false,
      logLevelList: [],
      logLevelListCodesys: [],
      logLevelListLib: [],
      REQ_CONF_TIMEOUT: 60,
      TIMEOUT: 120000,
      newTab: 0,
      isTabChanged: false,
      filebeatError: false,
      timeout: null,
      DNA_STATUSES,
      time: {
        hours: 1,
        minutes: 0,
      },
      found_codesys: {},
      logObj: {},
      changedTimeout: false,
      isNodeChanged: false,
    };
  },
  async created() {
    this.newTab = 0;
    this.username = JSON.parse(localStorage.getItem('userDetails')).username;
    if (localStorage.getItem(this.username)) {
      this.iconView = JSON.parse(localStorage.getItem(this.username)).config.iconView;
      this.tableView = !this.iconView;
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'selectedNode.devices': function (newValue, oldValue) {
      this.loadingTable = (!!(this.selectedNode && this.selectedNode.devices.length > 0
          && this.selectedNode.devices[0].type));
      this.selectedNode.devices.forEach((element, index) => {
        if (!element.memory && oldValue && oldValue[index] && oldValue[index].memory) {
          element.memory = oldValue[index].memory;
        }
      });
    },
    // eslint-disable-next-line func-names
    'selectedNode.currentFWVersion': function () {
      if (this.selectedNode && this.selectedNode.currentFWVersion) {
        this.nodeVersionInfo = !shared.isNoPrerelesedVersionGreaterThanOrEqualTo(this.selectedNode.currentFWVersion, '2.5.0');
        this.isDnaSupported = shared.isNoPrerelesedVersionGreaterThanOrEqualTo(this.selectedNode.currentFWVersion, '2.6.0');
        const isNewComposeSupported = shared.isNoPrerelesedVersionGreaterThanOrEqualTo(this.selectedNode.currentFWVersion, '2.7.0');
        this.$store.dispatch('node-tree/set_is_new_compose_supported', isNewComposeSupported);
      }
    },
    // eslint-disable-next-line func-names
    'selectedNode.serialNumber': function () {
      this.isDnaSupported = shared.isNoPrerelesedVersionGreaterThanOrEqualTo(this.selectedNode.currentFWVersion, '2.6.0');
      if (!this.isDnaSupported) {
        return;
      }
      this.$mqtt.unsubscribeFrom('dna', { serialNumber: this.selectedNode.serialNumber });
      if (this.selectedNode && this.selectedNode.serialNumber) {
        setTimeout(() => {
          this.$mqtt.subscribeTo('dna', { serialNumber: this.selectedNode.serialNumber });
        }, 100);
      }
    },
    // eslint-disable-next-line func-names
    'selectedNode.connectionStatus': function (newValue) {
      if ((newValue === 'online') && this.canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS')
        && this.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS') && this.newTab === 1) {
        this.newTab = 0;
      }
      this.isNodeOnlineAndDnaSupported();
      this.isNodeChanged = true;
    },
    // eslint-disable-next-line func-names
    'selectedNode.name': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.newTab = 0;
      }
      this.isNodeOnlineAndDnaSupported();
      this.isNodeChanged = true;
    },
    newTab(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.isTabChanged = true;
        if (!this.changedTimeout) {
          this.time = {
            hours: 1,
            minutes: 0,
          };
        }
      }
    },
  },
  computed: {
    nerveSimpleTableHeaders() {
      return [
        {
          text: this.$t('nodes.information.type'),
          align: 'start',
          value: 'type',
          filterable: false,
          component: {
            sfc: () => import('@/components/nodes/node-information/node-information-type.vue'),
          },
        },
        { text: this.$t('nodes.information.name'), value: 'name' },
        { text: this.$t('nodes.information.versionName'), value: '_workloadVersionName', filterable: false },
        {
          text: this.$t('nodes.information.status'),
          value: '_currentStatus',
          filterable: false,
          component: {
            sfc: () => import('@/components/nodes/node-information/node-information-status.vue'),
          },
        },
        { text: this.$t('nodes.information.cpu'), value: 'memory.cpu', filterable: false },
        { text: this.$t('nodes.information.ram'), value: 'memory.ram', filterable: false },
        { text: this.$t('nodes.information.deployed'), value: '_deployTime', filterable: false },
        {
          text: this.$t('nodes.information.lastChange'),
          value: '_lastChange',
          filterable: false,
        },
        {
          text: this.$t('nodes.information.connections'),
          value: 'connections',
          filterable: false,
          component: {
            sfc: () => import('@/components/nodes/node-information/node-information-connections.vue'),
            props: {
              selectedNode: this.selectedNode,
            },
          },
        },
      ];
    },
    nerveSimpleTableHeadersOlderVersionOfNode() {
      return [
        {
          text: this.$t('nodes.information.type'),
          align: 'start',
          value: 'type',
          filterable: false,
          component: {
            sfc: () => import('@/components/nodes/node-information/node-information-type.vue'),
          },
        },
        { text: this.$t('nodes.information.name'), value: 'name' },
        {
          text: this.$t('nodes.information.status'),
          value: '_currentStatus',
          filterable: false,
          component: {
            sfc: () => import('@/components/nodes/node-information/node-information-status.vue'),
          },
        },
        { text: this.$t('nodes.information.cpu'), value: 'memory.cpu', filterable: false },
        { text: this.$t('nodes.information.ram'), value: 'memory.ram', filterable: false },
        {
          text: this.$t('nodes.information.connections'),
          value: 'connections',
          filterable: false,
          component: {
            sfc: () => import('@/components/nodes/node-information/node-information-connections.vue'),
            props: {
              selectedNode: this.selectedNode,
            },
          },
        },
      ];
    },
    selectedNode() {
      return this.$store.getters['node-tree/getSelectedNode'];
    },
    elkUrl() {
      return `${'opensearch/app/discover#/?'
            + '_g=(refreshInterval:(pause:!t,value:0),'
            + 'time:(from:now-3h,to:now))'
            + "&_a=(columns:!('@timestamp',host.hostname,message,syslog.severity_label),filters:!(('$state':(store:appState),"
            + "meta:(alias:!n,disabled:!f,index:'243502e0-6185-11ea-b149-f7a361aca0a3',"
            + "key:tags,negate:!f,params:(query:'"}${ this.selectedNode.serialNumber
            }'),type:phrase,value:'${ this.selectedNode.serialNumber
            }'),query:(match:(tags:(query:'${ this.selectedNode.serialNumber
            }',type:phrase)))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'243502e0-6185-11ea-b149-f7a361aca0a3',key:syslog.severity_label,negate:!f,params:!(Error,Warning),type:phrases,value:'Error, Warning'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(syslog.severity_label:Error)),(match_phrase:(syslog.severity_label:Warning))))))),index:'243502e0-6185-11ea-b149-f7a361aca0a3',interval:auto,query:(language:kuery,query:''),sort:!('@timestamp',desc))`;
    },
    monitoringAndLoggingStatus() {
      return this.$store.getters['nodes/getMonitoringAndLoggingSettings'];
    },
    isSaveEnabled() {
      return this.$store.getters['nodes/isSaveEnabled'];
    },
    isViewSystemSaveEnabled() {
      return this.$store.getters['nodes/isViewSystemSaveEnabled'];
    },
    isDockerSystemSaveEnabled() {
      return this.$store.getters['nodes/isDockerSystemSaveEnabled'];
    },
    isLogLevelSaveEnabled() {
      return this.$store.getters['nodes/isLogLevelSaveEnabled'];
    },
    dnaStatus() {
      return storeDna.getTargetConfiguration;
    },
    logList() {
      return this.$store.getters['nodes/getLogLevelList'];
    },
  },
  methods: {
    async monitoringAndLoggingSetting() {
      if (!this.isNodeChanged) {
        return;
      }
      this.$store.dispatch('nodes/disable_view_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
      this.$store.dispatch('nodes/disable_docker_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
      this.$store.dispatch('nodes/disable_log_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
      this.isNodeChanged = false;
      if (!(this.canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS')
        && this.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS'))
      ) {
        try {
          this.$store.dispatch('nodes/log_and_metrics', { serialNumber: this.selectedNode.serialNumber, connectionStatus: this.selectedNode.isOnline() });
        } catch (err) {
          Logger.error(err);
        }
        return;
      }
      this.$store.dispatch('nodes/monitoring_and_logging_settings', { serialNumber: this.selectedNode.serialNumber, connectionStatus: this.selectedNode.isOnline() });
      this.initialObject = {
        data: { lang: this.userDetails[0] },
        dataId: 'node_logging',
        serialNumber: this.selectedNode.serialNumber,
        requestConfig: {
          forceRequestNoCacheUpdate: true,
          timeout: this.REQ_CONF_TIMEOUT,
          forceRequest: false,
        },
      };
      if (this.selectedNode.isOnline()) {
        await this.$store.dispatch('nodes/get_log_level_list', this.initialObject);
      }
      if (this.logList.values && !this.logList.values.libvirt) {
        this.filebeatError = true;
      }
      if (this.logList.values.ovdm) {
        const { options } = this.logList.values.ovdm.ovdm;
        const names = options.map((option) => option.value);
        this.logLevelList = names;
      }
      if (this.logList.values.libvirt) {
        const { options } = this.logList.values.libvirt.libvirt_ctl;
        const values = options.map((option) => option.name.toLowerCase());
        this.logLevelListLib = values;
        this.filebeatError = false;
      }
      if (this.logList.values.codesys) {
        const { options } = this.logList.values.codesys.codesys_ctl;
        const values = options.map((option) => option.name.toLowerCase());
        this.logLevelListCodesys = values;
        this.filebeatError = false;
      }
    },
    goToWorkloadControlPage(params) {
      this.$store.dispatch('node-tree/set_version_id_of_selected_wl', params.workload.versionId);
      this.$router.push({
        name: 'Deployed workload control',
        params: {
          currentGtwId: params.node.id,
          id: params.workload.id,
        },
      });
    },
    onResize() {
      this.isMarginVisible = window.innerWidth < 960;
    },
    tabChanged(tab) {
      this.newTab = tab;
    },
    setIconTableView() {
      localStorage.setItem(this.username, JSON.stringify({ config: { iconView: this.iconView } }));
    },
    async cancel() {
      if (!(this.canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS')
         && this.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS'))
      ) {
        await this.$store.dispatch('nodes/log_and_metrics', { serialNumber: this.selectedNode.serialNumber, connectionStatus: this.selectedNode.isOnline() });
      } else {
        await this.$store.dispatch('nodes/monitoring_and_logging_settings', { serialNumber: this.selectedNode.serialNumber, connectionStatus: this.selectedNode.isOnline() });
      }

      this.$store.dispatch('nodes/disable_view_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
      this.$store.dispatch('nodes/disable_docker_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
      this.$store.dispatch('nodes/disable_log_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
    },
    async submitForm() {
      if (!(this.canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS')
         && this.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS'))
      ) {
        try {
          await this.$store.dispatch('nodes/update_log_and_metrics', { serialNumber: this.selectedNode.serialNumber, data: this.monitoringAndLoggingStatus });
          this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'nodeLogLevel.successfullySaved',
          color: 'primary',
          showClose: true,
        });
        } catch (err) {
          Logger.error(err);
        }
        this.$store.dispatch('nodes/disable_view_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
        this.$store.dispatch('nodes/disable_docker_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
        return;
      }
      const lib_level = this.monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl.level;
      const lib_options = this.monitoringAndLoggingStatus.logLevels
        .values.libvirt.libvirt_ctl.options;
      const found_lib = lib_options.find((option) => option.name.toLowerCase() === lib_level);
      if (this.monitoringAndLoggingStatus.logLevels.values
        .codesys) {
        const codesys_level = this.monitoringAndLoggingStatus.logLevels.values
          .codesys.codesys_ctl.level;
        const codesys_options = this.monitoringAndLoggingStatus.logLevels.values
          .codesys.codesys_ctl.options;
        // eslint-disable-next-line no-unused-vars
        this.found_codesys = codesys_options
          .find((option) => option.name.toLowerCase() === codesys_level);
      }
      try {
        if (this.found_codesys && this.found_codesys.value) {
          await this.$store.dispatch('nodes/update_monitoring_and_logging_settings_V2', {
            serialNumber: this.selectedNode.serialNumber,
            data: {
              loggingMonitoring: this.monitoringAndLoggingStatus.loggingMonitoring,
              logLevels: {
                ovdm: this.monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level,
                libvirt_ctl: found_lib.value.toString(),
                codesys_ctl: this.found_codesys.value.toString(),
                timeout: this.REQ_CONF_TIMEOUT,
              },
            },
          });
          this.logObj = {
            dataId: 'node_logging',
            serialNumber: this.selectedNode.serialNumber,
            timeout: this.TIMEOUT,
            data: {
              command: 'set_loggers',
              params: {
                ovdm: this.monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level,
                libvirt_ctl: found_lib.value.toString(),
                codesys_ctl: this.found_codesys.value.toString(),
                timeout: this.TIMEOUT,
              },
            },
          };
        } else {
          await this.$store.dispatch('nodes/update_monitoring_and_logging_settings_V2', {
            serialNumber: this.selectedNode.serialNumber,
            data: {
              loggingMonitoring: this.monitoringAndLoggingStatus.loggingMonitoring,
              logLevels: {
                ovdm: this.monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level,
                libvirt_ctl: found_lib.value.toString(),
                timeout: this.REQ_CONF_TIMEOUT,
              },
            },
          });
          this.logObj = {
            dataId: 'node_logging',
            serialNumber: this.selectedNode.serialNumber,
            timeout: this.TIMEOUT,
            data: {
              command: 'set_loggers',
              params: {
                ovdm: this.monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level,
                libvirt_ctl: found_lib.value.toString(),
                timeout: this.TIMEOUT,
              },
            },
          };
        }

        await this.$store.dispatch('node-log-level/set_log_level_list', this.logObj);
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'nodeLogLevel.successfullySaved',
          color: 'primary',
          showClose: true,
        });
      } catch (e) {
        Logger.error(e);
      }
      this.$store.dispatch('nodes/disable_view_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
      this.$store.dispatch('nodes/disable_docker_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
      this.$store.dispatch('nodes/disable_log_system_button', { isChangedLogLevel: false, isViewChanged: false, isChangedDockerSystem: false });
      this.changedTimeout = true;
    },
    // eslint-disable-next-line func-names
    isNodeOnlineAndDnaSupported: debounce(async function () {
      if (this.selectedNode.connectionStatus !== 'online') {
        storeDna.fetchTargetConfigurationEmpty();
        return;
      }
      if (this.isDnaSupported) {
        storeDna.fetchTargetConfiguration(this.selectedNode.serialNumber);
      }
    }, 100),
  },
  mounted() {
    this.isNodeChanged = true;
    if (this.canAccess('UI_NODE_TREE:NODE_DETAILS')) {
      this.$store.dispatch('node-tree/restart_mqtt_events', this.selectedNode);
    }
    if (this.selectedNode.currentFWVersion) {
      this.isDnaSupported = shared.isNoPrerelesedVersionGreaterThanOrEqualTo(this.selectedNode.currentFWVersion, '2.6.0');
      this.nodeVersionInfo = !shared.isNoPrerelesedVersionGreaterThanOrEqualTo(this.selectedNode.currentFWVersion, '2.5.0');
    }
    this.newTab = 0;
    if (this.isDnaSupported) {
      this.$mqtt.subscribeTo('dna', { serialNumber: this.selectedNode.serialNumber });
      this.isNodeOnlineAndDnaSupported();
    }
  },
  beforeDestroy() {
    this.$store.dispatch('node-tree/clear_exchange_interval');
    this.$mqtt.unsubscribeFrom('node', this.selectedNode);
    if (this.isDnaSupported) {
      this.$mqtt.unsubscribeFrom('dna', { serialNumber: this.selectedNode.serialNumber });
    }
  },
};
</script>
  <style scoped>

    .node-devices-scroll {
      overflow-y: auto;
      max-height: 46vh;
    }
    .pointer {
      cursor: pointer !important;
    }
  </style>
