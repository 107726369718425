<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <v-list-item-icon
    v-if="isTypeOf(node, TREE_NODE_TYPES.ROOT)"
    class="ml-4"
  >
    <v-icon
      v-if="!isLoading"
      id="iiotNodeTreeCollapse"
      class="root-node-expand-icon"
      :title="$t(`nodes.tree.${isTreeExpanded ? 'collapseAll' : 'expandAll'}`)"
      @click.prevent.stop="expandOrCollapse">
      {{isTreeExpanded ? 'mdi-arrow-expand-up' : 'mdi-arrow-expand-down'}}
    </v-icon>
    <v-icon
      v-else
      class="loading-icon">
        mdi-loading
    </v-icon>
  </v-list-item-icon>
</template>

<script>
import { TREE_NODE_TYPES } from '@/model/node-tree/node-tree.model';
import Logger from '@/utils/logger';

export default {
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      TREE_NODE_TYPES,
    };
  },
  computed: {
    isTreeExpanded() {
      return this.$store.getters['node-tree/getIsTreeExpanded'];
    },
    isLoading() {
      return this.$store.getters['node-tree/getIsLoading'];
    },
  },
  methods: {
    expandOrCollapse() {
      try {
        this.$store.dispatch('node-tree/expand_or_collapse_all_nodes');
      } catch (e) {
        Logger.error(e);
      }
    },
    isTypeOf(node, type) {
      return node.data.type === type;
    },
  },
};
</script>
<style lang="scss">

.root-node-expand-icon {
  font-size: 16px !important;
}

.loading-icon {
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
