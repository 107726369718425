<!--
 * TTTech nerve-management-system
 * Copyright(c) 2021 TTTech Industrial Automation AG.
 *
 * ALL RIGHTS RESERVED.
 * Usage of this software, including source code, netlists, documentation,
 * is subject to restrictions and conditions of the applicable license
 * agreement with TTTech Industrial Automation AG or its affiliates.
 *
 * All trademarks used are the property of their respective owners.
 * TTTech Industrial Automation AG and its affiliates do not assume any liability
 * arising out of the application or use of any product described or shown
 * herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 * make changes, at any time, in order to improve reliability, function or
 * design.
 *
 * Contact Information:
 * support@tttech-industrial.com
 * TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 -->

<template>
  <div>
    <v-btn
      v-if="canAccess('UI_NODE:VIDEO_OUTPUT_CONFIG')
      && (selectedNode.videoOption || selectedNode.gridDisplay)"
      id="iiotVideoOupputConfigurationBtn"
      text
      color="primary"
      class="pa-0"
      :disabled="!selectedNode.isOnline()"
      @click="videoOutputConfig">
      {{$t('nodes.information.videoOutputConfiguration')}}
    </v-btn>
    <vm-video-options
      :input-array="vmList"
      :is-video-option-enabled="nodeInfoVideoOption"
      :is-dialog-open="dialogVmState"
      :is-grid-display="videoGridDisplay"
      @close="closeDialog"
      @save="saveDialog"
    />
  </div>
</template>

<script>
import { VmVideoOptions } from 'nerve-ui-components';
import Logger from '@/utils/logger';

export default {
  name: 'VideoOutputConfiguration',
  components: { VmVideoOptions },
  props: {
    selectedNode: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    vmList() {
      const { filterVMs } = this.selectedNode;
      return filterVMs;
    },
    nodeInfoVideoOption() {
      return this.selectedNode.videoOption;
    },
    dialogVmState() {
      return this.$store.getters['workloads/getDialogVmState']();
    },
    videoGridDisplay() {
      return this.selectedNode.gridDisplay;
    },
  },
  methods: {
    closeDialog() {
      this.$store.dispatch('workloads/close_dialog_vm_video_option');
    },
    async saveDialog(items) {
      try {
        const { serialNumber } = this.selectedNode;
        await this.$store.dispatch('nodes/set_video_output_configuration', { serialNumber, configs: items });
      } catch (e) {
        Logger.error(e);
      }
    },
    async videoOutputConfig() {
      const { name, serialNumber, filterVMs } = this.selectedNode;
      Logger.error(`VideoOutputConfiguration:: ${name} - ${serialNumber}:: `);
      Logger.error(filterVMs);
      if (!this.vmList.length) {
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: this.$t('nodes.information.vmVideoOutputNoVMsDeployed'),
          color: 'primary',
          showClose: true,
        });
        return;
      }
      this.vmVideoOption();
    },
    vmVideoOption() {
      this.$store.dispatch('workloads/show_dialog_vm_video_option');
    },
  },
};
</script>
