var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('span',{staticClass:"font-size-14 text--disabled"},[_vm._v(" "+_vm._s(_vm.$t('nodes.dna.status'))+" ")]),_c('span',{staticClass:"font-size-16",class:[
      {'status-color-green' : _vm.status === _setup.DNA_STATUSES.applied},
      {'status-color-blue fading' : _vm.status === _setup.DNA_STATUSES.canceling},
      {'status-color-orange' : _vm.status === _setup.DNA_STATUSES.modified},
      {'status-color-blue fading' : _vm.status === _setup.DNA_STATUSES.reconfiguring},
      {'status-color-red' : _vm.status === _setup.DNA_STATUSES.undefined}
    ],attrs:{"id":"iiotStatusColor"}},[_vm._v(" "+_vm._s(_vm.$t(`nodes.dna.${_vm.status}`))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }