var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"nodeInformationDockerSystem"},[_c('v-col',{staticClass:"ml-1",attrs:{"col":"12","md":"12","s":"6","xs":"6"}},[_c('v-row',[_c('span',{staticClass:"node-information-title"},[_vm._v(" "+_vm._s(_vm.$t("nodes.information.docker"))+" ")])]),_c('v-row',[_c('v-col',{staticClass:"pa-0 mt-3",attrs:{"cols":"7"}},[_c('v-btn',{staticClass:"pa-0 ma-0",attrs:{"text":"","color":"primary","href":_vm.dockerWorkloadLogging,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('nodes.information.monitoringAndLoggingSettingsDialog.dockerLogging'))+" ")]),_c('v-row',[_c('span',{staticClass:"text--secondary node-information-text-light mt-3 ml-3"},[_vm._v(" "+_vm._s(_vm.$t("nodes.information.dockerLogDescription"))+" ")])])],1),(_vm.canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS') &&
        _vm.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS'))?_c('v-col',{staticClass:"pt-9",attrs:{"cols":"1"}},[(_vm.selectedNode.isOnline() && (_vm.monitoringAndLoggingStatus &&
           _vm.monitoringAndLoggingStatus.loggingMonitoring) && !_vm.filebeatError)?_c('v-checkbox',{class:{'mt-4': _vm.filebeatError, 'mt-1': !_vm.filebeatError},attrs:{"id":"iiotNodeInformationDockerSystemCheckboxDockerLogging","data-cy":"iiotNodeInformationDockerSystemCheckboxDockerLogging"},on:{"click":_vm.changed},model:{value:(_vm.monitoringAndLoggingStatus.loggingMonitoring.dockerLogging),callback:function ($$v) {_vm.$set(_vm.monitoringAndLoggingStatus.loggingMonitoring, "dockerLogging", $$v)},expression:"monitoringAndLoggingStatus.loggingMonitoring.dockerLogging"}}):_vm._e()],1):_c('v-col',{staticClass:"pt-9",attrs:{"cols":"1"}},[(_vm.selectedNode.isOnline() && _vm.monitoringAndLoggingStatus
        && !_vm.filebeatError)?_c('v-checkbox',{staticClass:"mt-1",attrs:{"id":"iiotNodeInformationDockerSystemCheckboxDockerLogging","data-cy":"iiotNodeInformationDockerSystemCheckboxDockerLogging"},on:{"click":_vm.changed},model:{value:(_vm.monitoringAndLoggingStatus.dockerLogging),callback:function ($$v) {_vm.$set(_vm.monitoringAndLoggingStatus, "dockerLogging", $$v)},expression:"monitoringAndLoggingStatus.dockerLogging"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",class:{'mt-8': (!_vm.selectedNode.isOnline() ||
        (!_vm.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS') && _vm.selectedNode.isOnline())) ,
        'mt-4': (_vm.selectedNode.isOnline() &&
        _vm.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS'))},attrs:{"cols":"7"}},[_c('v-btn',{attrs:{"text":"","color":"primary","href":_vm.dockerMonitoringUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('nodes.information.monitoringAndLoggingSettingsDialog.dockerMonitoring'))+" ")]),_c('v-row',[_c('span',{staticClass:"text--secondary node-information-text-light mt-3 ml-3"},[_vm._v(" "+_vm._s(_vm.$t("nodes.information.dockerMetricsDescription"))+" ")])])],1),(_vm.canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS') &&
        _vm.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS'))?_c('v-col',{staticClass:"mt-3",attrs:{"cols":"1"}},[(_vm.selectedNode.isOnline() && (_vm.monitoringAndLoggingStatus &&
          _vm.monitoringAndLoggingStatus.loggingMonitoring) && !_vm.filebeatError)?_c('v-checkbox',{staticClass:"mt-5",attrs:{"id":"iiotNodeInformationDockerSystemCheckboxDockerMonitoring","data-cy":"iiotNodeInformationDockerSystemCheckboxDockerMonitoring"},on:{"click":_vm.changed},model:{value:(_vm.monitoringAndLoggingStatus.loggingMonitoring.dockerMonitoring),callback:function ($$v) {_vm.$set(_vm.monitoringAndLoggingStatus.loggingMonitoring, "dockerMonitoring", $$v)},expression:"monitoringAndLoggingStatus.loggingMonitoring.dockerMonitoring"}}):_vm._e()],1):_c('v-col',{staticClass:"pt-6",attrs:{"cols":"1"}},[(_vm.selectedNode.isOnline() && _vm.monitoringAndLoggingStatus
        && !_vm.filebeatError)?_c('v-checkbox',{staticClass:"mt-5",attrs:{"id":"iiotNodeInformationDockerSystemCheckboxDockerMonitoring","data-cy":"iiotNodeInformationDockerSystemCheckboxDockerMonitoring"},on:{"click":_vm.changed},model:{value:(_vm.monitoringAndLoggingStatus.dockerMonitoring),callback:function ($$v) {_vm.$set(_vm.monitoringAndLoggingStatus, "dockerMonitoring", $$v)},expression:"monitoringAndLoggingStatus.dockerMonitoring"}}):_vm._e()],1)],1)],1),_c('v-col')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }