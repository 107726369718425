<template>
  <div>
    <span class="font-size-14 text--disabled">
      {{ $t('nodes.dna.status') }}
    </span>
    <span
      id="iiotStatusColor"
      :class="[
        {'status-color-green' : status === DNA_STATUSES.applied},
        {'status-color-blue fading' : status === DNA_STATUSES.canceling},
        {'status-color-orange' : status === DNA_STATUSES.modified},
        {'status-color-blue fading' : status === DNA_STATUSES.reconfiguring},
        {'status-color-red' : status === DNA_STATUSES.undefined}
      ]"
      class="font-size-16"
    >
    {{ $t(`nodes.dna.${status}`) }}
    </span>
  </div>
</template>

<script setup>
import { DNA_STATUSES } from '@/constants';

/*
  props
*/

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  status: {
    required: true,
  },
});

</script>

<style>
  .status-color-red {
    color: #EB0C0C
  }
  .status-color-green {
    color: #44B384;
  }
  .status-color-blue {
    color: #0093D0
  }
  .status-color-orange {
    color: #F7A605
  }
</style>
